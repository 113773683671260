import { Link } from "gatsby";
import React from "react";

import HeaderBar from "../../components/docs/layout/HeaderBar";
import DocumentationSection from "../../components/docs/DocumentationSection";
import CrossGuideLinks from "../../components/docs/navigation/CrossGuideLinks";
import { StaticPageContainer } from "../../components/docs/shared-components/PageContainers";
import { RouterLocation } from "../../types/types";
import DocsHelmet from "../../components/docs/shared-components/DocsHelmet";

interface Props {
  location: RouterLocation;
}
function SupplementalDataOverviewPage({ location }: Props) {
  const title = "Supplemental Data";
  const description = "Learn how to get original data from a specific integration.";
  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Overview" className="mb-9">
        <>
          <p>
            If your product requires data beyond our Common Models, don’t worry! Anything that’s
            possible by building directly is also possible through Merge. We offer multiple ways to
            extend the data that we return outside of our common models.
          </p>
          <p>Here are the ways to include supplemental data for Merge integrations</p>
          <ul>
            <li className="mb-2">
              <Link to="/supplemental-data/remote-data">Remote Data</Link>{" "}
              <p>
                Get the latest integration data in the original format that Merge received it in
                with a simple query parameter. Ideal for when you’re looking for extra data not
                included in Merge’s standardized format.
              </p>
            </li>

            <li>
              <Link to="/supplemental-data/field-mappings/overview/">Field Mappings</Link>{" "}
              <p>
                Add additional fields to Merge’s Common Model or override any existing Merge field.
                Useful if you want to tailor our Common Models to fit your needs without writing any
                code.
              </p>
            </li>

            <li>
              <Link to="/supplemental-data/passthrough/overview">
                Authenticated Passthrough Request
              </Link>{" "}
              <p>
                Make an authenticated request to the third-party API using stored Linked Account
                credentials. Perfect for interacting directly with the native APIs of specific
                integrations, enabling more customization and functionality.
              </p>
            </li>
          </ul>
        </>
      </DocumentationSection>
      <CrossGuideLinks location={location} />
    </StaticPageContainer>
  );
}

export default SupplementalDataOverviewPage;
